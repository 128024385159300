<template>
  <div id="page-fournisseur-commande-list">
    <div class="vx-card p-6">

      <div class="vx-col w-full">

        <vs-table v-model="selected" pagination max-items="10" search :data="br" multiple>
          <template slot="header">
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown vs-trigger-click class="cursor-pointer">

              <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                <span class="mr-2 leading-none">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>CSV</span>
                  </span>
                </vs-dropdown-item>

              </vs-dropdown-menu>
            </vs-dropdown>
            <template v-if="activeUserInfos.privileges.find(item => item === 'nouveau_br')&&activeUserInfos.privileges.find(item => item === 'create')">
            <vs-button
              class="btn-primary ml-4 mb-2 sm:mb-0"
              color="primary"
              @click="newCommande">
              <!-- <feather-icon icon="UserPlusIcon" svgClasses="h-3 w-4" /> -->
              Nouveau BR

            </vs-button>
          </template>
          </template>
          <template slot="thead">
            <vs-th>
              N°
            </vs-th>
            <vs-th sort-key="CodeCmde">
              N° BR
            </vs-th>
            <vs-th sort-key="NomOffic">
              Officine
            </vs-th>
            <vs-th sort-key="RegimeOffic">
              Fournisseur
            </vs-th>
            <vs-th sort-key="status">
              Status
            </vs-th>
            <vs-th>
              %
            </vs-th>
            <vs-th>
              Montant BR
            </vs-th>
            <vs-th>
              Actions
            </vs-th>

          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="indextr+1">
                {{indextr + 1}}
              </vs-td>

              <vs-td :data="tr.CodeCmde">
                <cell-renderer-link :id="tr._id" :CodeCmde="tr.CodeCmde" />
              </vs-td>
              <vs-td>
                <cell-renderer-officine :officineId="tr.IdOfficine" />
              </vs-td>
              <vs-td>
                <cell-renderer-fournisseur :IdFour="tr.IdFour" />
              </vs-td>
              <vs-td>
                <cell-renderer-status :status="tr.status" />
              </vs-td>
              <vs-td>
                <cell-renderer-transfert :details="tr.details" />
              </vs-td>
              <vs-td>
                <cell-renderer-montant-commande :details="tr.details" />
              </vs-td>

              <vs-td>
                <cell-renderer-br-transferer-actions :id="tr._id" />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>

    </div>
  </div>

</template>

<script>
import vSelect from 'vue-select'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererBrTransfererActions from './cell-renderer/CellRendererBrTransfererActions.vue'
import CellRendererOfficine from './cell-renderer/CellRendererOfficine.vue'
import CellRendererFournisseur from './cell-renderer/CellRendererFournisseur.vue'
import CellRendererMontantCommande from './cell-renderer/CellRendererMontantCommande.vue'
import CellRendererTransfert from './cell-renderer/CellRendererTransfert.vue'


export default {
  components: {
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererBrTransfererActions,
    CellRendererOfficine,
    CellRendererFournisseur,
    CellRendererMontantCommande,
    CellRendererTransfert
  },
  data () {
    return {
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : null,
      selected: []
    }
  },
  computed: {
    br () {
      return this.$store.state.commande_fourn.commande_fourns_transferer
    }
  },
  methods: {
    newCommande () {
      this.$router.push({ path: '/apps/commandefourn/add' })
    },
    getTransfererBonReceptionByOfficine (id) {
      const payload = {
        officineId: id
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('commande_fourn/getTransfererBonReceptionByOfficine', payload)
        .then((resp) => {
          console.log('brs', resp.data)
          this.$vs.loading.close()
        })
        .catch(err => {
          console.log(err)
          this.$vs.loading.close()
        })
    }
  },
  created () {
    if (!(this.$store.state.commande_fourn.commande_fourns_transferer.length > 0)) this.getTransfererBonReceptionByOfficine(this.activeUserInfos.IdOfficine)
  }

}

</script>

<style lang="scss">
#page-fournisseur-commande-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
