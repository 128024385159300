<template>
  <div class="flex items-center">
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">{{ CodeCmde }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  props: ['CodeCmde', 'id'],
  computed: {
    url () {
      return `/apps/commandefourn/view/${this.id}`
    }
  }
}
</script>
