<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon title="Voir BR" icon="EyeIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="goToDetailFacture" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererBrTransfererActions',
  props: ['id'],
  data () {
    return {
      activeUserInfos: null
    }
  },
  computed:{

  },
  methods: {
    goToDetailFacture () {
      this.$router.push(`/apps/commandefourn/view/${this.id}`).catch(() => {})
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.activeUserInfo()
  }
}
</script>
